<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Garante - Editar</strong>

            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/garante/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">

              <b-row>
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select ref="city" v-model="guarantor.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                  <small v-if="errors.document_type"  class="form-text text-danger" >Seleccione un tipo de documento</small>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input v-model="guarantor.document_number" class="form-control" ></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary"  @click="SearchGuarantor"><b-icon icon="search"></b-icon></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Nombres :">
                    <b-form-input type="text"  v-model="guarantor.name"></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger" >Ingrese un nombre o razón social</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Nacimiento:">
                    <b-form-input type="date" v-model="guarantor.birth_date"></b-form-input>
                    <small v-if="errors.birth_date" class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Ubigeo:">
                    <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100" v-model="mubigee" :options="ubigee"></v-select>
                  </b-form-group>
                </b-col>


                <b-col md="4">
                  <b-form-group label="Dirección :">
                    <b-form-input type="text"  v-model="guarantor.address"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="4">
                  <b-form-group label="Email :">
                    <b-form-input type="email"  v-model="guarantor.email"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Celular :">
                    <b-form-input type="text"  v-model="guarantor.cell_phone"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-textarea type="text"  v-model="guarantor.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select v-model="guarantor.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>

              </b-row>
              
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ApiQuery from "@/assets/js/APIQuery";
import CodeToName from "@/assets/js/CodeToName";
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_guarantor"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Guarantor',
      role: 3,
      guarantor: {
        id_client:'',
        id_branch_office:'',
        document_type:1,
        document_number:'',
        birth_date:'',
        name:'',
        cell_phone:'',
        email:'',
        address:'',
        ubigee:'',
        observation:'',
        state:1,
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
      },
      mubigee:null,
      ubigee: [],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      error_document_number:'',
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      allowed_age: {
        minimum_age:0,
        maximum_age:0,
      },
      errors: {
        document_number:false,
        name: false,
        birth_date: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewGuarantor();
    this.GetAllowedAges();
    this.ListUbigeos();
  },
  methods: {
    ViewGuarantor,
    EditGuarantor,
    Validate,
    SearchGuarantor,
    GetAllowedAges,
    ListUbigeos,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ListUbigeos() {
  this.ubigee = [];
  let url = this.url_base + "list-ubigee";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.ubigee.push({value:element.id_ubigee,text:element.department+', '+element.province+', '+element.district })
    }
  })
}

function GetAllowedAges() {
  let url = this.url_base + "data/guarantor-get-allowed-ages";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
    if (response.data.status == 200) {
        me.allowed_age.minimum_age = parseFloat(response.data.result.minimum_age);
        me.allowed_age.maximum_age = parseFloat(response.data.result.maximum_age);
    }
  })
}

function ViewGuarantor() {
  let me = this;
  let id_guarantor = je.decrypt(this.id_guarantor);
  let url = me.url_base + "guarantor/view/" + id_guarantor;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  }).then(function (response) {
      if (response.data.status == 200) {
        me.guarantor.id_guarantor = response.data.result.id_guarantor;
        me.guarantor.id_branch_office = response.data.result.id_branch_office;
        me.guarantor.document_type = response.data.result.document_type;
        me.guarantor.document_number = response.data.result.document_number;
        me.guarantor.name = response.data.result.name;
        me.guarantor.birth_date = response.data.result.birth_date;
        me.guarantor.cell_phone = response.data.result.cell_phone;
        me.guarantor.observation = response.data.result.observation;
        me.guarantor.email = response.data.result.email;
        me.guarantor.address = response.data.result.address;
        me.guarantor.state = response.data.result.state;
        if (response.data.result.ubigee.length > 0) {
          me.mubigee = {value:response.data.result.ubigee,text:response.data.result.ubigee_value }  
        }

      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000, })
      }
      me.isLoading = false;
  }).catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000, })
      me.isLoading = true;
  });
}


function SearchGuarantor() {

  let me = this;
  if (me.guarantor.document_type == 1) {
      if (me.guarantor.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.guarantor.document_number).then((data) => {
          if (data.status == 200) {
            me.guarantor.name = data.razon_social;
            me.guarantor.address = data.direccion;
          }else{
            me.guarantor.name = '';
            me.guarantor.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.guarantor.document_type == 6) {
      if (me.guarantor.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.guarantor.document_number).then((data) => {
          if (data.status == 200) {
            me.guarantor.name = data.razon_social;
            me.guarantor.address = data.direccion;
          }else{
            me.guarantor.name = '';
            me.guarantor.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }

}

function EditGuarantor() {
  let me = this;
  let url = me.url_base + "guarantor/edit";
  me.guarantor.ubigee = me.mubigee == null ? '': me.mubigee.value;
  let data = me.guarantor;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  if (this.guarantor.document_type == 1) {
    this.errors.document_number = this.guarantor.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.guarantor.document_type == 6){
    this.errors.document_number = this.guarantor.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.guarantor.document_type == 4){
    this.errors.document_number = this.guarantor.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.guarantor.document_type == 7){
    this.errors.document_number = this.guarantor.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.guarantor.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.name = this.guarantor.name.length == 0 ? true : false;
  this.errors.birth_date = this.guarantor.birth_date.length == 0 ? true : false;

  if (this.errors.document_number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.birth_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  const birth_date = moment(this.guarantor.birth_date);
  const date_now = moment(this.guarantor.date_now);
  if (birth_date.isValid()) {
    const guarantor_age = date_now.diff(birth_date, 'years');
    if (parseFloat(guarantor_age) < parseFloat(this.allowed_age.minimum_age) || parseFloat(guarantor_age) > parseFloat(this.allowed_age.maximum_age)) {
      Swal.fire({ icon: 'warning', text: 'La edad Permitido para registrar un garante es mayor e igual que ' + this.allowed_age.minimum_age + ' y menor o igual a '+ this.allowed_age.maximum_age , timer: 2000,}); 
      return false;
    }
  } else {
    Swal.fire({ icon: 'warning', text: 'Ingrese una fecha de nacimiento correcta', timer: 2000,}); 
    return false;
  }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el garante ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditGuarantor();
    }
  });
}

</script>
